import config from '../../config.js';
import callApi from '../../util/apiCaller.js';
import { sequence } from '../../util/promises.js';

import { setMounted } from './AppActions.js';
import { getOrdersByUserRequest } from '../Order/OrderActions.js';
import { getUserGroupRequest } from '../User/UserGroupActions.js';
import { isLoggedInRequest } from '../User/UserActions.js';
import { getAddressesRequest } from '../Address/AddressActions.js';
import { addError } from '../Error/ErrorActions.js';

export function initApp(isSSR = false, initActions = []) {
    // console.log('INIT APP');
    return dispatch => {
        return (isSSR ? Promise.resolve(null) : dispatch(isLoggedInRequest()).catch(err => null)).then(user => {
            if(user) {
                initActions.push(getOrdersByUserRequest, getAddressesRequest);
                switch(user.role) {
                    case 'admin':
                        // initActions.push();
                        break;

                    case 'customer':
                    default:
                        // initActions = initActions.concat([]);
                        break;
                }
            // } else {
            } else {
                // isSSR && initActions.push(getUserGroupRequest);
            }
            return sequence(initActions, dispatch).then(results => {
                dispatch(setMounted());
                if(typeof window !== 'undefined' && window.dataLayer) {
                    window.dataLayer.push({ event: 'optimize.callback' });
                }
            }).catch(err => {
                console.error('AppInitializationError', err);
            });
        });
    };
}

export function checkVersionRequest() {
    return dispatch => callApi('app/web/version').then(res => {
        if(res.version && res.version !== config.version) {
            if(process.env.NODE_ENV !== 'development') {
                typeof window !== 'undefined' && window.location.reload();
            } else {
                console.log('App::: Need refresh');
            }
        }
        return res.version;
    }).catch(error => {
        dispatch(addError(error));
    });
}

export function clearCacheRequest() {
    return dispatch => callApi('app/cache/reset').then(res => {
        return res && res.ok;
    }).catch(error => {
        dispatch(addError(error));
        return false;
    });
}

export function launchCronRequest(key, param) {
    return dispatch => {
        return callApi('cron/launch', 'post', { cron: { key, param } }).then(res => {
            return res.ok;
        }).catch(error => {
            dispatch(addError(error));
            return null;
        });
    };
}

export function backupAppRequest() {
    return dispatch => callApi('app/backup').then(res => {
        return res && res.ok;
    }).catch(error => {
        dispatch(addError(error));
        return false;
    });
}
