import React, { PureComponent, Fragment, useState, useEffect, startTransition } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Row, Col, Form, InputGroup, ButtonGroup, Button, Badge } from 'react-bootstrap';

import { capitalizeFirstLetter, copyToClipboard } from '../../../../util/text.js';
import { dateFormat, dateTimestamp } from '../../../../util/date.js';

import { getOrdersRequest } from '../../../Order/OrderActions.js';
import { getOrderOption, getOrderStatusColor } from '../../../Order/OrderHelpers.js';
import { getVouchersRequest, getVouchers } from '../../../Voucher/VoucherActions.js';
import { getProductsRequest, getProducts, getProductsFromStore } from '../../../Product/ProductActions.js';
import { searchCmsContentsRequest } from '../../../Cms/CmsActions.js';
import { getUsersRequest, getLoggedUser, loginByIdRequest, getUserRolesForConsole } from '../../../User/UserActions.js';
import { getCountryFromLanguage, getDefaultLanguage } from '../../../Intl/IntlHelper.js';

import withRouter from '../../../../components/Router/WithRouter.js';
import Icon from '../../../../components/Content/Icon.js';
import Flag from '../../../../components/Flag/Flag.js';
import HtmlComponent from '../../../../components/Text/HtmlComponent.js';
import FormattedMessageConditionnal from '../../../Intl/components/Translate/FormattedMessageConditionnal.js';

function SearchMini({ dispatch, navigate, intl, products = [], vouchers = [], user = null, toggle = null }) {
	const [mounted, setMounted] = useState(false);
	const [isOpen, setIsOpen] = useState(false);
	const [isFocus, setIsFocus] = useState(false);
	const [search, setSearch] = useState('');
	const [data, setData] = useState({});

    const fetch = () => {
        const requests = [getOrdersRequest];
        if(user?.role === 'admin') {
            requests.push(getUsersRequest, searchCmsContentsRequest);
        }
        Promise.all(requests.map(caller => dispatch(caller(search, {}, {}, { current: 0, size: 50 })))).then(([orders, users, contents]) => {
			const timestamp = dateTimestamp();
			if(!data.timestamp || data.timestamp < timestamp) {
				setData({
					timestamp,
					users,
					products: products.filter(product => product.name.toLowerCase().includes(search.toLowerCase())),
					vouchers: vouchers.filter(voucher => ['name', 'code', 'description'].some(key => (voucher[key] || '').toLowerCase().includes(search.toLowerCase()))),
					orders,
					contents,
				});
			} else {
				console.log('Previous request', timestamp, data.timestamp);
			}
        });
    };

	const init = () => {
		if(!mounted && user && getUserRolesForConsole().includes(user?.role)) {
			setMounted(true);
			dispatch(getProductsRequest(user?.role === 'admin'));
			dispatch(getVouchersRequest());
		}
	};

    // useEffect(() => {
	// 	init();
	// }, []);

    useEffect(() => {
		init();
	}, [user]);

	useEffect(() => {
		if(search.length > 2) {
			setTimeout(() => {
				fetch();
			}, 300);
		}
	}, [search]);

    const handleChange = event => {
        const { target } = event;
        const { name, value } = target;
		setSearch(value);
    };

    const handleLogAs = event => dispatch(loginByIdRequest(event.target.getAttribute('data-user') || event.target.parentNode.getAttribute('data-user'))).then(() => navigate('/'));

    const handleCopyToClipboard = event => copyToClipboard(event.target.getAttribute('data-text') || event.target.parentNode.getAttribute('data-text'));

    const toggleFocus = event => {
        setTimeout(() => {
			setIsFocus(!isFocus);
        }, 200);
    };

    const renderResult = (key, type, displayFields, picto, renderLinks = null) => {
        const items = data[key];
        if(items && items.length) {
            return (
                <div className="ms-2 mb-2 pt-2 text-primary">
                    <h6 className="text-secondary"><Icon icon={picto} /> <FormattedMessageConditionnal id={key} defaultMessage={capitalizeFirstLetter(key)} /></h6>
                    {items.map(item => (
                        <div key={item._id} className="d-flex justify-content-between ms-3 my-1 py-1 border-bottom">
                            <div>
                                {
                                    Array.isArray(displayFields)
                                    ? displayFields.map((displayField, index) => (
                                        <Fragment key={index}>
                                            {index === 0 ? <strong>{item[displayField]}</strong> : <span>{item[displayField]}</span>}
                                            {(index + 1) < displayFields.length && ' - '}
                                        </Fragment>
                                    ))
                                    : displayFields(item)
								}
                            </div>
                            {
                                renderLinks
                                ? renderLinks(item)
                                : <Button variant="secondary" size="sm" as={Link} to={`/fr/console/${type}/${item._id}`}><Icon icon="eye" /></Button>
                            }
                        </div>
                    ))}
                </div>
            );
        }
    };

    const renderResults = () => {
        if(search && search.length > 2 && isFocus) {
            return (
                <div className="w-100 py-3 px-2 bg-white border shadow" style={{ position: 'absolute', width: '100%', zIndex: '10', maxHeight: '300px', overflow: 'auto' }}>
                    {['admin', 'manager'].includes(user.role) && renderResult('products', 'product', item => (
                        <Fragment>
                            <Icon icon="shape-circle" className={`text-${item.isActive ? 'success' : 'danger'}`} />
                            {!item.isAvailable && <Icon icon="cart-plus" className="text-danger" />}
                            <strong>{item.isPack && <Icon icon="cube" />} {item.name}</strong> - <em>{item.priceTTC}€</em><br />
                            {item.weight}Kg / {item.categories.map(category => <Badge size="sm" variant="light" key={category}>{category}</Badge>)}
                            {(item.options || {}).manageStock && (
                                <span className={`text-${item.stock <= item.packing ? 'danger' : 'black'}`}>
                                     / {item.stock} left
                                </span>
                            )}
                        </Fragment>
                    ), 'cubes', item => (
                        <ButtonGroup>
                            <Button variant="secondary" size="sm" as={Link} to={`/fr/${item.slug}`}><Icon icon="eye" /></Button>
                            <Button variant="warning" size="sm" as={Link} to={`/fr/console/product/${item._id}`}><Icon icon="pen" /></Button>
                        </ButtonGroup>
                    ))}

                    {['admin'].includes(user.role) && renderResult('contents', 'cms', item => (
                        <Fragment>
                            <Icon icon="shape-circle" className={`text-${item.isActive ? 'success' : 'danger'}`} /> <Flag country={getCountryFromLanguage(item.language)} /> <strong>{item.title}</strong><br />
                            {item.type}/{item.model} : {item.language}/{item.slug}<br />
                        </Fragment>
                    ), 'pen', item => (
                        <ButtonGroup>
                            <Button variant="secondary" size="sm" as={Link} to={`/${item.language}/${item.slug}`}><Icon icon="eye" /></Button>
                            <Button variant="outline-secondary" size="sm" data-text={`/${item.language}/${item.slug}`} onClick={handleCopyToClipboard}><Icon icon="clipboard" /></Button>
                            <Button variant="warning" size="sm" as={Link} to={`/fr/console/content/${item._id}`}><Icon icon="pen" /></Button>
                        </ButtonGroup>
                    ))}

                    {renderResult('vouchers', 'voucher', item => (
                        <Fragment>
                            <strong>{item.name}</strong> - <em>{item.code} <Button variant="outline-link" data-text={item.code} onClick={handleCopyToClipboard}><Icon icon="clipboard" /></Button></em><br />
                            <HtmlComponent text={item.description} />
                            <Row>
                                <Col sm="6">
                                    <ul>
                                        {item.conditions.map(condition => (<li key={condition.key}>{condition.key} {condition.operator} {condition.value}</li>))}
                                    </ul>
                                </Col>
                                <Col sm="6">
                                    <ul>
                                        {item.actions.map(action => (<li key={action.key}>{action.key} {action.operator} {action.value}</li>))}
                                    </ul>
                                </Col>
                            </Row>
                        </Fragment>
                    ), 'tags')}

                    {renderResult('orders', 'order', item => (
                        <Fragment>
                            <Badge bg={getOrderStatusColor(item.status)}><FormattedMessageConditionnal id={`orderStatus${capitalizeFirstLetter(item.status)}`} defaultMessage={capitalizeFirstLetter(item.status)} /></Badge> <strong>{item.user && `${item.user.firstName} ${item.user.lastName}`}</strong> - <em>{item.user.group}</em><br />
                            {(getOrderOption(item, 'isMultiorder') || item.parent) && <strong><FormattedMessage id="orderMultiorderTitle" defaultMessage="Bulked order" /> </strong>}{item.code} - {dateFormat(item.dates.onpreparation || item.dates.created, 'LL')} - {(item.totals || {}).totalTTC || 0}€ {item.flags.map(flag => <Badge size="sm" variant="light" key={flag}>{flag}</Badge>)}
                        </Fragment>
                    ), 'list')}

                    {['admin', 'manager'].includes(user.role) && renderResult('users', 'user', item => (
                        <Fragment>
                            <strong>{item.firstName || ''} {item.lastName || ''}</strong> {item.company && `(${item.company})`} - <em>{item.group}</em><br />
                            <a href={`mailto:${item.email}`}>{item.email}</a> - <a href={`tel:${item.phone}`}>{item.phone || ''}</a>
                        </Fragment>
                    ), 'users', item => (
                        <ButtonGroup size="sm">
                            <Button variant="primary" as={Link} to={`/fr/console/user/${item._id}`}><Icon icon="eye" /></Button>
                            <Button variant="warning" data-user={item._id} onClick={handleLogAs}><Icon icon="enter" /></Button>
                            <Button variant="secondary" as={Link} to={`/fr/console/orders/user/${item._id}`}>{((item.dates || {}).orders || []).length} <Icon icon="list" /></Button>
                        </ButtonGroup>
                    ))}
                </div>
            );
        }
        return null;
    };

	if(mounted && user && getUserRolesForConsole().includes(user?.role)) {
		if(isOpen) {
			return (
				<Form className="me-auto my-2 my-md-0 w-100 mw-100 navbar-search" style={{ position: 'relative', width: '40%' }} onSubmit={event => event.preventDefault()}>
					<InputGroup size="sm">
						<Form.Control type="text" name="search" value={search} onChange={handleChange} onFocus={toggleFocus} onBlur={toggleFocus} className=" border-top-0 border-left-0 small" placeholder={intl.formatMessage({ id: 'searchFor', defaultMessage: 'Search...' })} autoComplete="off" autoFocus aria-label="Search" aria-describedby="search" />
						{toggle && <Button variant="link" className="m-0 p-1" onClick={() => { toggle && toggle(false); setIsOpen(false); }}><Icon icon="close-o" /></Button>}
					</InputGroup>
					{renderResults()}
				</Form>
			);
		}
		return <Button variant="outline-light" className="ms-3 p-0 border-0" onClick={() => { toggle(true); setIsOpen(true); }}><Icon icon="search" /></Button>;
	}
	return null;
}

function mapStateToProps(store, props) {
    return {
        user: getLoggedUser(store),
        products: getProductsFromStore(store),
        vouchers: getVouchers(store),
    };
}

SearchMini.propTypes = {
    dispatch: PropTypes.func.isRequired,
    navigate: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    products: PropTypes.arrayOf(PropTypes.object),
    vouchers: PropTypes.arrayOf(PropTypes.object),
    toggle: PropTypes.func,
};

export default connect(mapStateToProps)(withRouter(injectIntl(SearchMini)));
