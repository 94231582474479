import callApi from '../../util/apiCaller.js';
import { dateUtc, toDate } from '../../util/date.js';
import { formatPrice } from '../../util/price.js';

import { addError } from '../Error/ErrorActions.js';
import { getOrderRequest } from '../Order/OrderActions.js';

export const AFFILIATION_QUERY_PARAM = 'affiliation';

export const SET_AFFILIATIONS = 'SET_AFFILIATIONS';
export const SET_CURRENT_AFFILIATION = 'SET_CURRENT_AFFILIATION';
export const REMOVE_AFFILIATION = 'REMOVE_AFFILIATION';

export function getAffiliationsRequest() {
    return dispatch => {
        return callApi('affiliations').then(res => {
            res.affiliations && dispatch(setAffiliations(res.affiliations));
            return res.affiliations;
        }).catch(error => {
            // dispatch(addError(error));
            return null;
        });
    };
}

export function getAffiliationRequest(keyOrId) {
    return dispatch => {
        return callApi(`affiliation/${keyOrId}`).then(res => {
            res.affiliation && dispatch(setAffiliations([res.affiliation]));
            return res.affiliation;
        }).catch(error => {
            // dispatch(addError(error));
            return null;
        });
    };
}

export function editAffiliationRequest(affiliation) {
    return dispatch => {
        return callApi('affiliation/edit', 'post', { affiliation }).then(res => {
            res.affiliation && dispatch(setAffiliations([res.affiliation]));
            return res.affiliation;
        }).catch(error => {
            dispatch(addError(error));
            return null;
        });
    };
}

export function checkOrderAffiliationRequest(orderId) {
    return dispatch => {
        return callApi(`affiliation/order/${orderId}/check`).then(res => {
            return dispatch(getOrderRequest(orderId));
        }).catch(error => {
            dispatch(addError(error));
            return null;
        });
    };
}

export function removeAffiliationRequest(affiliationId) {
    return dispatch => {
        return callApi('affiliation/remove', 'delete', { affiliation: { _id: affiliationId } }).then(res => {
            dispatch(removeAffiliation(affiliationId));
            return res.ok;
        }).catch(error => {
            dispatch(addError(error));
            return null;
        });
    };
}

export function getAffiliationOrdersRequest(period, userId = '') {
    return callApi(`affiliation/${userId ? `${userId}/` : ''}orders/${period}`).then(res => {
        return res;
    }).catch(error => {
        return null;
    });
}

// Getters
export function getAffiliations(store) {
    return store.affiliations.data;
}

export function getAffiliation(store, keyOrId) {
    return (store.affiliations.data.find(affiliation => affiliation._id === keyOrId || affiliation.key === keyOrId) || {});
}

export function getCurrentAffiliation(store) {
    return store.affiliations.currentAffiliation || {};
}

export function getAffiliationOption(affiliation, option) {
    return (affiliation && affiliation.options && affiliation.options[option]) || '';
}

export function getAffiliationLegal(affiliation, legal) {
    return (affiliation && affiliation.legal && affiliation.legal[legal]) || '';
}

export function getAffiliationCommissionBase(order) {
    return formatPrice((order.totals?.totalTTC || 0) - (order.totals?.tax || 0) - (order.totals?.shippingFee || 0));
}

export function getAffiliationCommission(affiliation, order) {
    return formatPrice((getAffiliationCommissionBase(order) * (parseFloat(affiliation.commission) || 0)) / 100);
}

export function setAffiliations(affiliations) {
    return {
        type: SET_AFFILIATIONS,
        affiliations,
    };
}

export function setCurrentAffiliation(affiliationKey, date = null) {
    return {
        type: SET_CURRENT_AFFILIATION,
        currentAffiliation: {
            key: affiliationKey,
            date: toDate(dateUtc(date)).toISOString(),
        },
    };
}

export function removeAffiliation(affiliationId) {
    return {
        type: REMOVE_AFFILIATION,
        affiliationId,
    };
}
