import { getPriceCent, getPriceFromCent, formatPrice } from '../../util/price.js';

import { getVoucherDiscountValue } from '../Voucher/VoucherActions.js';
import { getShippingFee, getRealShippingFee } from '../Shipping/ShippingHelpers.js';
import { getProductPrice } from '../Product/ProductActions.js';
import { USER_GROUP_DEFAULT } from '../User/UserGroupActions.js';
import { getUserOption } from '../User/UserActions.js';

import productSimulation from '../Product/ProductSimulation.js';
import userGroupSimulation from '../User/UserGroupSimulation.js';
import { ORDER_CHARGES } from './Charges.js';
import { getAffiliationCommission } from '../Affiliation/AffiliationActions.js';

export function getOrderUserId(order) {
    return order && order.user && order.user._id ? order.user._id : (order || {}).user;
}

export function getOrderOption(order, option) {
    return (order && order.options && order.options[option]) || '';
}

export function getOrderItemsTotal(items, user = null) {
    return getPriceFromCent(items.reduce((total, item) => total + getPriceCent(item[user && getUserOption(user, 'exemptVAT') ? 'priceHT' : 'priceTTC'] * item.quantity), 0));
}

export function getOrderTax(items, user = null) {
    if(user && getUserOption(user, 'exemptVAT')) {
        return 0;
    }
    return formatPrice(items.reduce((total, item) => {
        const lineTotalTTC = item.priceTTC * item.quantity;
        const lineTotalHT = lineTotalTTC / (1 + parseFloat(item.taxRate));
        const lineTotalTax = lineTotalTTC - lineTotalHT;
        return total + lineTotalTax;
    }, 0));
}

export function getOrderTotals(order) {
    const totalItems = getOrderItemsTotal(order.items, order.user);
    const totalShippingFee = getShippingFee((order.shipping || {}).address, (order.shipping || {}).method, order.items, order.user);
    const totalVouchers = (order.vouchers || []).reduce((total, voucher) => getVoucherDiscountValue(voucher, order.items, totalShippingFee, { exemptVAT: getUserOption(order.user, 'exemptVAT') }), 0);
    const totalTTC = Math.max(0, getPriceFromCent(
        getPriceCent(totalItems)
        + getPriceCent(totalShippingFee || 0)
        - getPriceCent(totalVouchers),
    ));
    return {
        items: totalItems,
        tax: getOrderTax(order.items, order.user),
        shippingFee: totalShippingFee,
        discount: totalVouchers,
        totalTTC,
    };
}

export function getOrderBenefit(order, type = 'amount', isSimulation = false) {
    const resume = {
    };
	if(!(order.flags || []).includes('gift')) {
		if(isSimulation) {
			order = getSimulatedOrder(order);
		}

		resume.totalTTC = (order.totals || {}).totalTTC || 0;

		// VAT
		resume.vat = (order.totals || {}).tax || 0;

		// Company Cost
		resume.companyCostHT = formatPrice((order.items || []).reduce((total, item) => {
			if(!parseFloat(item.companyCostHT)) {
				process.env.NODE_ENV === 'production' && console.error('Missing company cost', item);
				// if(!resume.missed) {
				//     resume.missed = [];
				// }
				// resume.missed.push(item);
			}
			return total + (item.quantity * parseFloat(item.companyCostHT || 0));
		}, 0));

		// Payment
		resume.payment = formatPrice((order.payments || [{ method: 'MISSING_PAYMENT' }]).reduce((total, payment) => {
			let commission = 0;
			if(['card', 'creditcard', 'bancontact', 'gyropay', 'ideal'].includes(payment.method)) {
				commission += formatPrice((0.015 * ((order.totals || {}).totalTTC || 0)) + 0.25) || 0;
			} else if(payment.method === 'paypal') {
				commission += formatPrice((0.029 * ((order.totals || {}).totalTTC || 0)) + 0.35) || 0;
			} else if(payment.method === 'banktransfer') {
				commission += 0.01;
			} else if(payment.method === 'MISSING_PAYMENT') { // Average charge between Stripe & Paypal (in case of stats calculation due to database encryption)
				commission += formatPrice((0.023 * ((order.totals || {}).totalTTC || 0)) + 0.30) || 0;
			}
			return total + commission;
		}, 0)) || ((((order.totals || {}).totalTTC || 0) * ORDER_CHARGES.payment) / 100);

		// Shipping
		resume.shipping = getRealShippingFee(order) || (order.totals || {}).shipping || ((((order.totals || {}).totalTTC || 0) * ORDER_CHARGES.shipping) / 100);
		resume.expedition = 0.54; // prix du carton blanc

		if(order.affiliation && order.affiliation.isActive) {
			resume.affiliation = getAffiliationCommission(order.affiliation, order);
		}

		if(order.options?.commission) {
			resume.commission = (parseFloat(order.options.commission) * (order.totals || {}).totalTTC) / 100;
		}

		// Left
		resume.left = formatPrice(((order.totals || {}).totalTTC || 0) - Object.keys(resume).filter(key => !['totalTTC'].includes(key)).reduce((total, key) => total + parseFloat(resume[key]), 0));
		// if(isNaN(resume.left)) {
		//     console.error(order, resume);
		// }
	}
    if(type === 'detail') {
        return resume;
    }
    // console.log(resume);
    return formatPrice(type === 'percent' ? ((order.totals || {}).totalTTC ? (((resume.left || 0) * 100) / ((order.totals || {}).totalTTC || 0)) : 0) : resume.left) || 0;
}

export function getSimulatedOrder(order) {
    const userGroupIdentifier = ((order.user || {}).group || {}).identifier || (order.user || {}).group || USER_GROUP_DEFAULT;
    const items = (order.items || []).map(item => {
        // (order.user.group.identifier || order.user.group) !== 'individual' && console.log(order.user.group.identifier || order.user.group, item.name, item.priceTTC, getProductPrice({ ...item, ...productSimulation[item.productId.toString()] }, 1, userGroupSimulation[order.user.group.identifier || order.user.group]));
        return {
            ...item,
            ...(productSimulation[item.productId.toString()] || {}),
            priceTTC: getProductPrice({ ...item, ...productSimulation[item.productId.toString()] }, 1, userGroupSimulation[userGroupIdentifier]),
            priceHT: getProductPrice({ ...item, ...productSimulation[item.productId.toString()] }, 1, userGroupSimulation[userGroupIdentifier], false),
        };
    });

    // console.log(order.items, items, order.totals, getOrderTotals({ ...order, items }));

    return {
        ...order,
        items,
        totals: getOrderTotals({ ...order, items }),
        affiliation: {
            commission: 8,
            isValid: true,
        },
    };
}

export function getMultiorderFromSuborders(parentOrder, suborders) {
    const multiorder = { ...parentOrder };
    const allOrders = [parentOrder, ...suborders];
    let mergedItems = [];
    allOrders.forEach(order => {
        (order.items || []).forEach(item => {
            if(mergedItems.find(existingItem => existingItem.productId === item.productId)) {
                mergedItems = mergedItems.map(existingItem => {
                    if(existingItem.productId === item.productId) {
                        return {
                            ...existingItem,
                            quantity: existingItem.quantity + item.quantity,
                        };
                    }
                    return existingItem;
                });
            } else {
                mergedItems.push(item);
            }
        });
    });
    multiorder.items = mergedItems;
    const totalsKeys = [];
    allOrders.forEach(order => {
        Object.keys(order.totals || {}).forEach(key => {
            if(!totalsKeys.includes(key)) {
                totalsKeys.push(key);
            }
        });
    });
    multiorder.totals = {};
    totalsKeys.forEach(key => {
        multiorder.totals[key] = formatPrice(allOrders.reduce((total, order) => total + ((order.totals || {})[key] || 0), 0));
    });
    return multiorder;
}

export function getOrderBenefitColor(benefitPercent) {
    if(benefitPercent > 40) {
        return 'success';
    }
    if(benefitPercent > 33) {
        return 'secondary';
    }
    if(benefitPercent > 20) {
        return 'light';
    }
    if(benefitPercent > 10) {
        return 'warning';
    }
    return 'danger';
}

export function getOrderStatuses() {
    return ['pendingpayment', 'onpreparation', 'shipped', 'complete', 'archived', 'canceled', 'dispute'];
}

export function getAvailableFlags() {
    return ['subtotal', 'packFill', 'packCount'];
}

export function getOrderStatusColor(status) {
    switch(status) {
        case 'pendingpayment':
            return 'warning';

        case 'onpreparation':
            return 'primary';

        case 'shipped':
            return 'info';

        case 'complete':
            return 'success';

        case 'canceled':
            return 'danger';

        case 'archived':
        default:
            return 'secondary';
    }
}

export function getPaymentStatusColor(status) {
    switch(status) {
        case 'pending':
            return 'warning';

        case 'failed':
            return 'danger';

        case 'complete':
            return 'success';

        default:
            return 'secondary';
    }
}
