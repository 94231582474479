import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import { getPreferedLanguage, getEnabledLanguages } from '../../IntlHelper.js';

import withRouter from '../../../../components/Router/WithRouter.js';
import { initLanguage } from '../../IntlActions.js';
import { getLoggedUser } from '../../../User/UserActions.js';
import { setDateLanguage } from '../../../../util/date.js';

function LocaleInitiator({ navigate, params, location, children }) {
    useEffect(() => {
        // console.log('LocaleInitiator::: mounted', params);
        if(!params.lang || !getEnabledLanguages().includes(params.lang)) {
            // console.log('LocaleInitiator::: Reset locale', getPreferedLanguage());
            navigate(`/${getPreferedLanguage()}/${location.pathname}`, { redirect: true });
        } else {
			setDateLanguage(params.lang);
		}
    }, []);

    return (
        <div>
            {children}
        </div>
    );
}

function mapStateToProps(store, props) {
    return {
        // user: getLoggedUser(store),
    };
}

// LocaleInitiator.defaultProps = {
//     user: null,
// };

LocaleInitiator.propTypes = {
    dispatch: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    navigate: PropTypes.func.isRequired,
    params: PropTypes.object.isRequired,
    children: PropTypes.any.isRequired,
    // user: PropTypes.object,
};

export default withRouter(injectIntl(LocaleInitiator));
