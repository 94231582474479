import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

export default function ProductWeight({ product, hideLabel = false }) {
    if(product && product.weight) {
		const weights = (product.weightNet || product.weight).split('-');

		const renderWeights = () => {
			return weights.map(weight => {
				let unit = 'Kg';
				if(weight < 1) {
					unit = 'g';
					weight *= 1000;
				}
				return `${weight} ${unit}`;
			}).join(' - ');
		};
        return <span>{!hideLabel && <small><FormattedMessage id="weightNet" defaultMessage="Net weight" />: </small>}<span>{renderWeights()}</span></span>;
    }
    return null;
}

ProductWeight.propTypes = {
    product: PropTypes.object.isRequired,
    hideLabel: PropTypes.bool,
};
