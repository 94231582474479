export function sequence(items, consumer) {
    const results = [];
    const currentItems = [...items];
    const runner = () => {
        const item = currentItems.shift();
        if(typeof item !== 'undefined') {
			// console.log('Sequence::: run with item', item);
            return consumer(typeof item === 'function' ? item() : item)
            .then(result => {
                results.push(result);
            })
            .then(runner);
        }
		// console.log('Sequence::: end');

        return Promise.resolve(results);
    };

    return runner();
}

export function sleep(milliseconds) {
    const start = new Date().getTime();
    for(let i = 0; i < 1e7; i++) {
        if((new Date().getTime() - start) > milliseconds) {
            break;
        }
    }
}
