import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { FormattedMessage } from 'react-intl';
import { Container, Row, Col, Card, Button, Alert } from 'react-bootstrap';
import Icon from '../../../../../components/Content/Icon.js';

import config from '../../../../../config.js';
import { getFile } from '../../../../../util/file.js';

import { getContentBlockItemsByName, getContentBlockItemByName, getContentBlockMixedItem, getContentBlockItemValue } from '../../../CmsActions.js';
import { checkVoucherConditions, getVouchers } from '../../../../Voucher/VoucherActions.js';

import Breadcrumbs from '../../../../App/components/view/Breadcrumbs.js';
import HtmlComponent from '../../../../../components/Text/HtmlComponent.js';
import FileLoader from '../../../../../components/Content/FileLoader.js';
import CmsBlockItem from '../../content/CmsBlockItem.js';
import PreFooter from '../../../../App/components/PreFooter.js';
import { USER_GROUP_DEFAULT } from '../../../../User/UserGroupActions.js';

function PageVouchers({ page, vouchers, locale }) {
    const cover = getContentBlockItemValue(page.blocks, 'image', 'path');
    const title = getContentBlockItemValue(page.blocks, 'title') || page.title;
    const contents = getContentBlockItemsByName(page.blocks, 'content');
    const promotion = getContentBlockItemByName(page.blocks, 'promotion');

    const renderContent = (content, isFullColumn = false, index = 0) => {
        const text = getContentBlockMixedItem(content, 'text') && <HtmlComponent text={getContentBlockMixedItem(content, 'text').text} />;
        const image = getContentBlockMixedItem(content, 'image') && getContentBlockMixedItem(content, 'image').path && <CmsBlockItem item={getContentBlockMixedItem(content, 'image')} type="image" className="mt-3 mt-md-0 w-100" />;
        const cta = getContentBlockMixedItem(content, 'call-to-action') && getContentBlockMixedItem(content, 'call-to-action').text && <div className="text-center"><Button variant="warning" size="lg" as={Link} to={getContentBlockMixedItem(content, 'call-to-action').path} className="mt-3">{getContentBlockMixedItem(content, 'call-to-action').text}</Button></div>;
        const position = getContentBlockMixedItem(content, 'position') && getContentBlockMixedItem(content, 'position').text;
        return (
            <div key={index} className={classnames('mb-5', !isFullColumn && 'critical-css-limiter')}>
                {
                    isFullColumn
                    ? (
                        <Fragment>
                            {image}
                            {text}
                            {cta}
                        </Fragment>
                    ) : (
                        <Fragment>
                            <Row>
                                {
                                    (!position || position === 'right')
                                    ? (
                                        <Fragment>
                                            <Col xs="12" md={image ? 8 : 12}>
                                                {text}
                                            </Col>
                                            {image && <Col>{image}</Col>}
                                        </Fragment>
                                    ) : (
                                        <Fragment>
                                            {image && <Col>{image}</Col>}
                                            <Col xs="12" md={image ? 8 : 12}>
                                                {text}
                                            </Col>
                                        </Fragment>
                                    )
                                }
                            </Row>
                            {cta}
                        </Fragment>
                    )
                }
            </div>
        );
    };

    vouchers = vouchers.filter(voucher => !voucher.conditions.find(condition => condition.key === 'user' || (condition.key === 'userGroup' && condition.value !== USER_GROUP_DEFAULT)) || checkVoucherConditions(voucher));

    // console.log(vouchers);

    return (
        <Fragment>
            <Helmet>
                <body data-header-contrast={cover ? 'true' : 'false'} />
            </Helmet>
            <div className={classnames(cover && 'header-cover', 'page-wrapper', 'bg-info')} style={{ backgroundImage: cover ? `url(${getFile(cover)})` : '', height: cover ? '550px' : '350px' }}>
                <Container className="ms-2 mx-md-auto mt-5 pt-5">
                    <h1 className={classnames('mt-2', 'ms-2', 'ms-md-0', 'w-100', 'w-md-50', 'border-half-danger', cover ? 'text-white' : 'text-dark')}>
                        {title}
                    </h1>
                </Container>
            </div>
            <Container className={classnames('mt-lg-n5')}>
                <Card className={classnames('px-2', 'p-md-5')}>
                    <Card.Body>
                        {contents && contents.length && contents.map((content, index) => {
                            if(index === 0 && promotion) {
                                return (
                                    <Row key={index} className="my-3">
                                        <Col xs={{ span: 12, order: 2 }} md={{ span: 7, order: 1 }}>
                                            {renderContent(content, true, index)}
                                        </Col>
                                        <Col xs={{ span: 12, order: 1 }} md={{ span: 5, order: 2 }} className="mb-3">
                                            <div className="d-none d-md-block bg-tertiary" style={{ paddingTop: '120px' }}>
                                                {getContentBlockMixedItem(promotion, 'image') && (
                                                    <div className="d-none d-md-block position-absolute w-100 text-center" style={{ top: '-300px', left: 0 }}>
                                                        <CmsBlockItem item={getContentBlockMixedItem(promotion, 'image')} type="image" style={{ maxHeight: '450px' }} />
                                                    </div>
                                                )}
                                                <div className="p-3">
                                                    {getContentBlockMixedItem(promotion, 'title') && <span className="h6 font-weight-bold">{getContentBlockMixedItem(promotion, 'title').text}</span>}
                                                    {getContentBlockMixedItem(promotion, 'call-to-action') && getContentBlockMixedItem(promotion, 'call-to-action').path && (
                                                        <Button variant="secondary" block as={Link} to={getContentBlockMixedItem(promotion, 'call-to-action').path} className="mt-3">{getContentBlockMixedItem(promotion, 'call-to-action').text}</Button>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="d-block d-md-none p-3 bg-tertiary">
                                                <div className="text-center"><CmsBlockItem item={getContentBlockMixedItem(promotion, 'image')} type="image" className="mb-3 w-75" /></div>
                                                {getContentBlockMixedItem(promotion, 'title') && <span className="h6 font-weight-bold">{getContentBlockMixedItem(promotion, 'title').text}</span>}
                                                {getContentBlockMixedItem(promotion, 'call-to-action') && getContentBlockMixedItem(promotion, 'call-to-action').path && getContentBlockMixedItem(promotion, 'call-to-action').text && (
                                                    <Button variant="secondary" block as={Link} to={getContentBlockMixedItem(promotion, 'call-to-action').path} className="mt-3">{getContentBlockMixedItem(promotion, 'call-to-action').text}</Button>
                                                )}
                                            </div>
                                        </Col>
                                    </Row>
                                );
                            }
                            return renderContent(content, false, index);
                        })}
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Body>
                        {
                            vouchers.length > 0
                            ? vouchers.map(voucher => (
                                <div key={voucher._id} className="mb-3 pb-3 border-bottom">
                                    <Row>
                                        <Col>
                                            <h2 className="border-half-warning">{voucher.name}</h2>
                                        </Col>
                                        <Col xs="4" className="text-start text-md-end">
                                            {voucher.code && <span className="h4">CODE: <strong className="d-inline-block p-1 text-bg-warning">{voucher.code.toUpperCase()}</strong></span>}
                                        </Col>
                                    </Row>
                                    <HtmlComponent text={voucher.description} />
                                    <div className="text-start text-md-end">
                                        {voucher.code && <Button variant="link" as={Link} to={`/${locale}/cart?vouchers=${voucher.code}`}><FormattedMessage id="voucherUseButton" defaultMessage="Use this coupon" /> <Icon icon="arrow-right" /></Button>}
                                    </div>
                                </div>
                            ))
                            : (
                                <Alert variant="warning">
                                    <Icon icon="danger" /> <FormattedMessage id="vouchersNo" defaultMessage="No vouchers" />
                                </Alert>
                            )
                        }
                    </Card.Body>
                </Card>
            </Container>
            <PreFooter />
        </Fragment>
    );
}

function mapStateToProps(store, props) {
    return {
        locale: store.intl.locale,
        vouchers: getVouchers(store, true),
    };
}

PageVouchers.defaultProps = {
    vouchers: [],
};

PageVouchers.propTypes = {
    page: PropTypes.object.isRequired,
    locale: PropTypes.string.isRequired,
    vouchers: PropTypes.arrayOf(PropTypes.object),
};

export default connect(mapStateToProps)(PageVouchers);
