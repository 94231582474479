import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import { displayErrors, removeError } from '../ErrorActions';

function ErrorDisclaimer({ dispatch, intl, errors }) {
	useEffect(() => {
		errors.forEach(error => {
			if(error && error.type && error.message) {
				console.log(`alert${error.message}`, intl.messages[`alert${error.message}`], error.message);
				displayErrors(error.type, intl.messages[`alert${error.message}`] || error.message);
			}
			dispatch(removeError(error.message));
		});
	}, [errors]);
	return null;
}

ErrorDisclaimer.propTypes = {
	dispatch: PropTypes.func.isRequired,
	intl: PropTypes.object.isRequired,
    errors: PropTypes.array.isRequired,
};

const mapStateToProps = (store, props) => ({
	errors: store.error.data,
});

export default connect(mapStateToProps)(injectIntl(ErrorDisclaimer));
