import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { REFERRAL_QUERY_PARAM, setReferral } from '../../AppActions';

import withRouter from '../../../../components/Router/WithRouter';

function AppReferral({ dispatch, search }) {
	useEffect(() => {
        if(search.get(REFERRAL_QUERY_PARAM)) {
            dispatch(setReferral(search.get(REFERRAL_QUERY_PARAM)));
        }
	}, []);

	return null;
}

AppReferral.propTypes = {
	dispatch: PropTypes.func.isRequired,
	search: PropTypes.object.isRequired,
};

export default connect()(withRouter(AppReferral, ['search']));
