import callApi from '../../util/apiCaller.js';

import { getPriceCent, getPriceFromCent } from '../../util/price.js';

import { getOrderRequest } from '../Order/OrderActions.js';
import { isConditionChecked } from '../Cart/CartActions.js';
import { getUserGroup, USER_GROUP_DEFAULT } from '../User/UserGroupActions.js';
import { addError } from '../Error/ErrorActions.js';

export const SET_SHIPPINGS = 'SET_SHIPPINGS';
export const REMOVE_SHIPPING = 'REMOVE_SHIPPING';

export function getShippingsRequest(refreshCache = false) {
    return dispatch => {
        return callApi(`shippings${refreshCache ? '?refreshCache=1' : ''}`).then(res => {
            res.shippings && dispatch(setShippings(res.shippings));
            return res.shippings;
        }).catch(error => {
            dispatch(addError(error));
            return null;
        });
    };
}

export function getShippingMethodsRequest(address) {
    return callApi('shippings/methods', 'post', { address }).then(results => {
        return results;
    }).catch(err => {
        console.error(err);
        return [];
    });
}

export function editShippingRequest(shipping) {
    return dispatch => {
        return callApi('shipping/edit', 'post', { shipping }).then(res => {
            dispatch(getShippingsRequest(true));
            return res.shipping;
        }).catch(error => {
            dispatch(addError(error));
            return null;
        });
    };
}

export function removeShippingRequest(shippingId) {
    return dispatch => {
        return callApi('shipping/remove', 'delete', { shipping: { _id: shippingId } }).then(res => {
            dispatch(removeShipping(shippingId));
            return res.ok;
        }).catch(error => {
            dispatch(addError(error));
            return null;
        });
    };
}

export function getShippingLabelRequest(orderId, options = { addOrReplace: '' }) {
    return dispatch => {
        return callApi(`order/${orderId}/shipping/label/${options.addOrReplace || ''}`, 'post', options).then(res => {
            return dispatch(getOrderRequest(orderId)).then(order => {
				return res;
			});
        }).catch(error => {
            dispatch(addError(error));
            return null;
        });
    };
}

export function setShippingTrackingRequest(orderId, trackingNumber) {
    return dispatch => {
        return callApi(`order/${orderId}/shipping/trackingnumber`, 'post', { trackingNumber }).then(res => {
            return res;
        }).catch(error => {
            // dispatch(addError(error));
            return null;
        });
    };
}

// Getters
export function getShippings(store) {
    return store.shippings.data;
}
export function getShipping(store, shippingId) {
    return store.shippings.data.find(shipping => shipping._id === shippingId);
}

export function getShippingByAddress(store, address, items = []) {
    return address && store.shippings.data.find(shipping => isShippingApplicable(shipping, address, items, getUserGroup(store)));
}

export function getShippingOption(shipping, option) {
    return (shipping.options || {})[option] || '';
}

export function isShippingApplicable(shipping, address, items = [], userGroup = null, options = {}) {
    // console.log('isShippingApplicable', shipping.identifier, shipping, address, userGroup);

    // console.log('Country', address.countryCode && (shipping.countries || []).includes(address.countryCode) ? 'OK' : 'KO');

    // console.log('Postal code', (
    //     !shipping.exclude
    //     || (
    //         !address.postalCode.trim()
    //         || !shipping.exclude.postalCodes
    //         || shipping.exclude.postalCodes.split(',').every(postalCode => isAddressDepartmentAvailableForShipping(address.postalCode.trim(), postalCode))
    //     )
    // ) ? 'OK' : 'KO');

    // console.log('User group', (
    //     (!userGroup && (!(shipping.userGroups || []).length || shipping.userGroups.includes(USER_GROUP_DEFAULT)))
    //     || !shipping.userGroups
    //     || !shipping.userGroups.length
    //     || (userGroup && shipping.userGroups.includes(userGroup.identifier))
    // ) ? 'OK' : 'KO');

    // console.log('Options: allowedForMultiorder', options.isMultiorder, getShippingOption(shipping, 'allowedForMultiorder'), (
    //     !!options.isMultiorder === !!getShippingOption(shipping, 'allowedForMultiorder')
    //     ) ? 'OK' : 'KO');

    // console.log('Shipping conditions', shipping.conditions);

	return address.countryCode && (shipping.countries || []).includes(address.countryCode.trim())
        && (
            !shipping.exclude
            || (
                !address.postalCode.trim()
                || !shipping.exclude.postalCodes
                || shipping.exclude.postalCodes.split(',').every(postalCode => isAddressDepartmentAvailableForShipping(address.postalCode.trim(), postalCode))
            )
        )
        && (
            (!userGroup && (!(shipping.userGroups || []).length || shipping.userGroups.includes(USER_GROUP_DEFAULT)))
            || !shipping.userGroups
            || !shipping.userGroups.length
            || (userGroup && shipping.userGroups.includes(userGroup.identifier))
        )
        && shipping.conditions.every(condition => {
            const needApplyCondition = (
                !(condition.userGroups || []).length
                || condition.userGroups.includes(userGroup ? userGroup.identifier : USER_GROUP_DEFAULT)
             );
            // needApplyCondition && !isConditionChecked(condition, items) && console.log('Condition not fullfiled', condition, items);
            return !needApplyCondition || isConditionChecked(condition, items);
        })
        && (
            !!options.isMultiorder === !!getShippingOption(shipping, 'allowedForMultiorder')
         );
}

export function isAddressDepartmentAvailableForShipping(addressPostalCode, postalCode) {
    const jokerIndex = postalCode.indexOf('*') > 0 ? postalCode.indexOf('*') : postalCode.length;
    return addressPostalCode.substring(0, jokerIndex) !== postalCode.replace('*', '');
}

export function setShippings(shippings) {
    return {
        type: SET_SHIPPINGS,
        shippings,
    };
}

export function removeShipping(id) {
    return {
        type: REMOVE_SHIPPING,
        id,
    };
}
