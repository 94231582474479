import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';

import { AFFILIATION_QUERY_PARAM, setCurrentAffiliation } from '../../AffiliationActions';

import withRouter from '../../../../components/Router/WithRouter';
import { REFERRAL_QUERY_PARAM } from '../../../App/AppActions';
import { dateSub, dateUtc } from '../../../../util/date';

function AffiliationInit({ dispatch, search }) {
	const affiliationKey = search.get(AFFILIATION_QUERY_PARAM) || search.get(REFERRAL_QUERY_PARAM);
	useEffect(() => {
        if(affiliationKey) {
			console.log('Init affiliation', affiliationKey);
            dispatch(setCurrentAffiliation(affiliationKey));
        }
	}, []);

	if(process.env.NODE_ENV === 'development' && affiliationKey) {
		return <Button onClick={() => dispatch(setCurrentAffiliation(affiliationKey, dateSub(dateUtc(), 5, 'days')))}>Set affiliation date</Button>;
	}
	return null;
}

AffiliationInit.propTypes = {
	dispatch: PropTypes.func.isRequired,
	search: PropTypes.object.isRequired,
};

export default connect()(withRouter(AffiliationInit, ['search']));
